import React from "react";
import { Typography } from "../../../ui-kit/components/Typography/Typography";

export const FreeTrialScreen = (props) => {
  const { t, startFreeTrial, commissionRate } = props;
  return (
    <div className="font-inter">
      <div className="ml-10">
        <Typography
          element="div"
          weight="semibold"
          size="lg"
          height="5"
          color="gray-975"
          className=" mt-10"
        >
          {t("trial_header")}
        </Typography>
        <Typography
          element="div"
          weight="normal"
          size="sm"
          height="5"
          color="gray-625"
          className="mt-3"
        >
          {t("trial_subheader1")}
        </Typography>
        <Typography
          element="div"
          weight="normal"
          size="sm"
          height="5"
          color="gray-625"
          className="mt-3"
        >
          {t("trial_subheader2")}
        </Typography>
      </div>
      <button
        className="mt-5 ml-10 bg-gray-975 rounded-lg font-semibold text-sm leading-5 text-white px-4 py-2 focus:outline-none hover:bg-black"
        onClick={startFreeTrial}
      >
        {t("start_free_trial")}
      </button>
      <a href={t("set_up_link")} target="_blank">
        <Typography
          element="div"
          weight="semibold"
          size="sm"
          height="5"
          color="gray-975"
          className="ml-5 mt-3 underline inline"
        >
          {t("learn_how_it_works")}
        </Typography>
      </a>
      <Typography
        element="div"
        weight="normal"
        size="xs"
        height="5"
        color="gray-625"
        className="ml-10 mt-3"
      >
        {t("signing_up_description", { commissionRate: commissionRate })}
      </Typography>
    </div>
  );
};
