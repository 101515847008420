import React from "react";

import Condition from "./condition";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleName: this.props.rule ? this.props.rule.name : "",
      shippingLabel: this.props.rule ? this.props.rule.shipments_labels : 1,
      is_arrived: this.props.rule
        ? this.props.rule.customer_return_is_arrived
        : false,
      is_inspected: this.props.rule
        ? this.props.rule.customer_return_is_inspected
        : false,
      on_hold: this.props.rule
        ? this.props.rule.customer_return_on_hold
        : false,
      useDhlReceiverId: this.props.rule
        ? !!this.props.rule.return_address_dhl_receiver_id
        : false,
      customAddress: this.props.rule ? this.props.rule.custom_address : false,
      notes: this.props.rule ? this.props.rule.customer_return_notes : "",
      active_methods: this.props.rule
        ? this.props.rule.merchant_return_method_ids
        : [],
      conditions: this.props.rule ? this.props.rule.conditions : [],
      address_name: this.props.rule ? this.props.rule.return_address_name : "",
      address_name2: this.props.rule
        ? this.props.rule.return_address_name2
        : "",
      street: this.props.rule ? this.props.rule.return_address_street : "",
      street_no: this.props.rule
        ? this.props.rule.return_address_street_number
        : "",
      zip_code: this.props.rule ? this.props.rule.return_address_zip : "",
      city: this.props.rule ? this.props.rule.return_address_city : "",
      state: this.props.rule ? this.props.rule.return_address_state : "",
      country: this.props.rule ? this.props.rule.return_address_country : "",
      return_address_dhl_receiver_id: this.props.rule
        ? this.props.rule.return_address_dhl_receiver_id
        : "",
      priority: this.props.rule
        ? this.props.rule.priority
        : this.props.ruleLength + 1,
      addCondition: this.props.rule && this.props.rule.conditions?.length > 0,
      t: this.props.t,
      showLabel: true,
      fields: Object.entries(this.props.fields).map(([k, v]) => ({
        value: k,
        label: v,
      })),
      number_predicates: Object.entries(this.props.number_predicates).map(
        ([k, v]) => ({ value: k, label: v })
      ),
      collection_predicates: Object.entries(
        this.props.collection_predicates
      ).map(([k, v]) => ({ value: k, label: v })),
      singular_predicates: Object.entries(this.props.singular_predicates).map(
        ([k, v]) => ({ value: k, label: v })
      ),
      return_types: Object.entries(this.props.return_types).map(([k, v]) => ({
        value: k,
        label: v,
      })),
      risk_levels: Object.entries(this.props.risk_levels).map(([k, v]) => ({
        value: k,
        label: v,
      })),
      reasons: Object.entries(this.props.reasons).map(([k, v]) => ({
        value: k,
        label: v,
      })),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rule !== this.props.rule) {
      this.setState({ conditions: this.props.rule.conditions });
      this.setState({
        useDhlReceiverId: !this.state.return_address_dhl_receiver_id,
      });
    }
  }

  addCondition = () => {
    const arr = this.state.conditions;
    arr.push({
      field: "order_country",
      predicate: "is_in",
      value: "",
    });
    this.setState({ conditions: arr, addCondition: true });
  };

  changeCondition = (arr) => {
    this.setState({ conditions: arr });
  };

  useDhlReceiverId = () => {
    this.setState({ useDhlReceiverId: !this.state.useDhlReceiverId });
  };

  addActiveMethod = (id) => {
    const arr = this.state.active_methods;
    const index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }

    this.setState({ active_methods: arr });
  };

  editForm = () => {
    const body = {
      rule: {
        name: this.state.ruleName,
        priority: this.state.priority,
        customer_return_is_arrived: this.state.is_arrived ? 1 : 0,
        customer_return_is_inspected: this.state.is_inspected ? 1 : 0,
        customer_return_on_hold: this.state.on_hold ? 1 : 0,
        customer_return_notes: this.state.notes,
        shipments_labels: this.state.shippingLabel,
        return_address_dhl_receiver_id: this.state.useDhlReceiverId
          ? this.state.return_address_dhl_receiver_id
          : "",
        custom_address: this.state.customAddress,
        conditions_attributes: this.state.conditions,
        return_address_name: this.state.address_name,
        return_address_name2: this.state.address_name2,
        return_address_street: this.state.street,
        return_address_street_number: this.state.street_no,
        return_address_zip: this.state.zip_code,
        return_address_city: this.state.city,
        return_address_state: this.state.state,
        return_address_country: this.state.country,
        merchant_return_method_ids: this.state.active_methods,
      },
    };
    this.props.submitEditForm(this.props.rule.id, body);
  };

  createForm = () => {
    const body = {
      rule: {
        name: this.state.ruleName,
        customer_return_is_arrived: this.state.is_arrived ? 1 : 0,
        customer_return_is_inspected: this.state.is_inspected ? 1 : 0,
        customer_return_on_hold: this.state.on_hold ? 1 : 0,
        customer_return_notes: this.state.notes,
        shipments_labels: this.state.shippingLabel,
        return_address_dhl_receiver_id: this.state.useDhlReceiverId
          ? this.state.return_address_dhl_receiver_id
          : "",
        custom_address: this.state.customAddress,
        priority: this.state.priority,
        conditions_attributes: this.state.conditions,
        return_address_name: this.state.address_name,
        return_address_name2: this.state.address_name2,
        return_address_street: this.state.street,
        return_address_street_number: this.state.street_no,
        return_address_zip: this.state.zip_code,
        return_address_city: this.state.city,
        return_address_state: this.state.state,
        return_address_country: this.state.country,
        merchant_return_method_ids: this.state.active_methods,
      },
    };

    this.props.submitCreateForm(body);
  };

  changeUseDhlReceiverId = (e) => {
    this.setState({ useDhlReceiverId: e.target.checked });
  };

  handleCheckBoxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  deleteCondition = (index, id) => {
    const arr = this.state.conditions;
    if (id) {
      arr[index] = {
        field: arr[index].field,
        predicate: arr[index].predicate,
        value: arr[index].value,
        _destroy: 1,
        id: id,
      };
    } else {
      arr[index] = {
        field: arr[index].field,
        predicate: arr[index].predicate,
        value: arr[index].value,
        _destroy: 1,
      };
    }

    if (arr.filter((a) => a._destroy == 1).length == arr.length) {
      this.setState({ conditions: arr, addCondition: false });
    } else {
      this.setState({ conditions: arr });
    }
  };

  render() {
    return (
      <div className="font-inter">
        <div className="flex justify-between items-center px-8 mb-3">
          <div className="font-medium text-xs leading-4">
            <p>
              <span className="text-gray-525">
                <span
                  className="cursor-pointer"
                  onClick={
                    this.props.rule
                      ? () => this.props.changeShowEditform(this.props.rule)
                      : this.props.addRuleHandler
                  }
                >
                  {this.state.t.rules}
                </span>{" "}
                /{" "}
              </span>
              <span className="text-gray-725 text-xs13">
                {this.props.rule ? this.props.rule.name : this.state.t.new_rule}
              </span>
            </p>
          </div>
          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>
              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between border-t border-b mb-8 pt-5 pb-5 px-8">
          <div className="flex items-center">
            <h5 className="font-semibold text-base leading-6 text-gray-975">
              {this.props.rule?.id && this.props.rule.name}
              {!this.props.rule?.id && this.state.t.new_rule}
            </h5>
          </div>
          {this.props.rule && (
            <button
              className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
              onClick={this.editForm}
            >
              {this.state.t.save_changes}
            </button>
          )}
          {!this.props.rule && (
            <button
              className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
              onClick={this.createForm}
            >
              {this.state.t.save}
            </button>
          )}
        </div>
        <div className="px-8 mb-10">
          <div className="pb-6 border-b mb-6 border-gray-225">
            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-4">
              {this.state.t.details}
            </h1>
            <div className="flex items-center w-full">
              <div className="mr-5 w-1/3">
                <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                  {this.state.t.name}
                </label>
                <input
                  className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                  name="ruleName"
                  defaultValue={this.props.rule?.name}
                  onChange={this.handleInputChange}
                ></input>
              </div>
            </div>
          </div>
          <div className="pb-6 border-b mb-6 border-gray-225">
            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-4">
              {this.props.t.conditions}
            </h1>

            {this.state.addCondition && (
              <div className="w-full">
                <div className="flex items-center justify-between">
                  <div className="w-1/3 mr-3">
                    <label className="font-normal text-xs13 leading-4 text-gray-625 mb-3 block w-max">
                      {this.state.t.filter}
                    </label>
                  </div>
                  <div className="w-1/3 mr-5">
                    <label className="font-normal text-xs13 leading-4 text-gray-625 mb-3 block w-max">
                      {this.state.t.value}
                    </label>
                  </div>
                </div>
                {this.state.conditions.map((condition, index) => (
                  <React.Fragment key={index}>
                    <Condition
                      index={index}
                      show={this.state.showLabel}
                      conditions={this.state.conditions}
                      condition={condition}
                      changeCondition={this.changeCondition}
                      current_merchant={this.props.current_merchant}
                      order_country_field={this.props.order_country_field}
                      return_type_field={this.props.return_type_field}
                      return_reason_field={this.props.return_reason_field}
                      customer_risk_level_field={
                        this.props.customer_risk_level_field
                      }
                      t={this.props.t}
                      deleteCondition={this.deleteCondition}
                      rules={this.props.rules}
                      showLabel={this.showLabel}
                      number_fields={this.props.number_fields}
                      collection_fields={this.props.collection_fields}
                      singular_fields={this.props.singular_fields}
                      fields={this.state.fields}
                      number_predicates={this.state.number_predicates}
                      collection_predicates={this.state.collection_predicates}
                      singular_predicates={this.state.singular_predicates}
                      return_types={this.state.return_types}
                      risk_levels={this.state.risk_levels}
                      reasons={this.state.reasons}
                      orderCountryOptions={this.props.orderCountryOptions}
                    />
                    {index < this.state.conditions.length - 1 && (
                      <div style={{ textAlign: "left", margin: "10px 0" }}>
                        <p>{this.props.t.and}</p>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
            <button
              className="bg-white border-blue-675 mt-4 border rounded-lg font-semibold text-sm leading-5 text-blue-675 px-4 py-2 focus:outline-none hover:bg-blue-625 hover:text-white focus:shadow-btn_blue"
              onClick={this.addCondition}
            >
              {this.props.t.add_Condition}
            </button>
          </div>
          <div className="pb-6 border-b mb-6 border-gray-225">
            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-2">
              {this.props.t.return_methods}
            </h1>
            <span className="font-normal text-xs13 leading-4 text-gray-625 mb-6 block w-full break-words">
              {this.props.t.return_methods_caption}
            </span>
            <div className="flex items-center flex-wrap">
              {this.props.active_methods.map((i, index) => (
                <div className="flex items-center mb-4 w-1/5 mr-2" key={index}>
                  <input
                    className="cursor-pointer min-w-24 h-6 w-6 rounded mr-2"
                    id={`return-method-${index}`}
                    type="checkbox"
                    onChange={() => this.addActiveMethod(i.id)}
                    defaultChecked={
                      this.state.active_methods.indexOf(i.id) > -1
                    }
                  ></input>
                  <label
                    htmlFor={`return-method-${index}`}
                    className="cursor-pointer font-medium text-sm leading-5 flex items-center text-gray-825"
                  >
                    {i.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="pb-11 border-b mb-6 border-gray-225">
            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-4">
              {this.props.t.no_shipping_labels}
            </h1>
            <input
              className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-1/3 font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
              type="number"
              min="1"
              name="shippingLabel"
              onChange={this.handleInputChange}
              defaultValue={this.state.shippingLabel}
            ></input>
          </div>
          <div className="pb-6 border-b mb-6 border-gray-225">
            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-2 capitalize">
              {this.props.t.return_address}
            </h1>
            <span className="font-normal text-xs13 leading-4 text-gray-625 mb-6 block w-full break-words">
              {this.props.t.return_address_caption}
            </span>
            <div className="flex items-center mb-4">
              <input
                className="cursor-pointer h-6 w-6 rounded mr-2"
                id="customAddress"
                name="customAddress"
                type="checkbox"
                onChange={this.handleCheckBoxChange}
                defaultChecked={this.state.customAddress}
              ></input>
              <label
                htmlFor="customAddress"
                className="cursor-pointer font-medium text-sm leading-5 flex items-center text-gray-825"
              >
                {this.state.t.use_custom}
              </label>
            </div>

            {this.state.customAddress && (
              <div className="w-4/6">
                <div className="flex items-center mb-4">
                  <div className="w-1/2 mr-5">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.first_name}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="address_name"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.address_name}
                    ></input>
                  </div>
                  <div className="w-1/2">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.last_name}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="address_name2"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.address_name2}
                    ></input>
                  </div>
                </div>
                <div className="flex items-center mb-4">
                  <div className="w-1/2 mr-5">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.street}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="street"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.street}
                    ></input>
                  </div>
                  <div className="w-1/2">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.street_no}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="street_no"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.street_no}
                    ></input>
                  </div>
                </div>
                <div className="flex items-center mb-4">
                  <div className="w-1/2 mr-5">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.zip_code}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="zip_code"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.zip_code}
                    ></input>
                  </div>
                  <div className="w-1/2">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.city}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="city"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.city}
                    ></input>
                  </div>
                </div>
                <div className="flex items-center mb-4">
                  <div className="w-1/2 mr-5">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.state}
                    </label>
                    <input
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="state"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.state}
                    ></input>
                    <span className="block mb-1 text-xs text-gray-600">
                      {this.state.t.state_caption}
                    </span>
                  </div>

                  <div className="w-1/2">
                    <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                      {this.state.t.country}
                    </label>
                    <select
                      className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                      name="country"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.country}
                    >
                      {this.props.customAddressCountryOptions.map(
                        (country, index) => (
                          <option
                            className="text-gray-750"
                            value={country.value}
                            key={index}
                          >
                            {country.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            )}

            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-5 capitalize">
              {this.state.t.dhl_integration}
            </h1>
            <div className="flex items-center mb-4">
              <input
                className="cursor-pointer h-6 w-6 rounded mr-2"
                id="dhlreceiverId"
                type="checkbox"
                name="useDhlReceiverId"
                onChange={this.changeUseDhlReceiverId}
                defaultChecked={this.state.useDhlReceiverId}
              ></input>
              <label
                htmlFor="dhlreceiverId"
                className="cursor-pointer font-medium text-sm leading-5 flex items-center text-gray-825"
              >
                {this.state.t.use_custom_dhl_receiver}
              </label>
            </div>

            {this.state.useDhlReceiverId && (
              <div className="w-4/6">
                <div className="w-1/2 pr-3">
                  <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
                    {this.state.t.dhl_receiver_id}
                  </label>
                  <input
                    className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px w-full font-medium text-sm leading-5 text-gray-875 border-gray-325 focus:border-blue-675 focus:border"
                    name="return_address_dhl_receiver_id"
                    onChange={this.handleInputChange}
                    value={this.state.return_address_dhl_receiver_id}
                  ></input>
                </div>
              </div>
            )}
          </div>

          <div className="pb-6">
            <h1 className="font-semibold text-base leading-6 text-gray-825 mb-2 capitalize">
              {this.state.t.status_options}
            </h1>
            <span className="font-normal text-xs13 leading-4 text-gray-625 mb-6 block w-full break-words">
              {this.props.t.status_options_caption}
            </span>

            <div className="flex items-center flex-wrap mb-5">
              <div className="flex items-center mb-4 mr-24 flex-wrap">
                <input
                  id="status-is-arrived"
                  className="cursor-pointer h-6 w-6 rounded mr-2"
                  type="checkbox"
                  name="is_arrived"
                  onChange={this.handleCheckBoxChange}
                  defaultChecked={this.state.is_arrived}
                ></input>
                <label
                  htmlFor="status-is-arrived"
                  className="cursor-pointer font-medium text-sm leading-5 flex items-center text-gray-825"
                >
                  {this.props.t.is_arrived}
                </label>
              </div>
              <div className="flex items-center mb-4 mr-24 flex-wrap">
                <input
                  id="status-is-inspected"
                  className="cursor-pointer h-6 w-6 rounded mr-2"
                  type="checkbox"
                  name="is_inspected"
                  onChange={this.handleCheckBoxChange}
                  defaultChecked={this.state.is_inspected}
                ></input>
                <label
                  htmlFor="status-is-inspected"
                  className="cursor-pointer font-medium text-sm leading-5 text-gray-825"
                >
                  {this.props.t.is_inspected}
                </label>
              </div>
              <div className="flex items-center mb-4 mr-24 flex-wrap">
                <input
                  id="status-is-onhold"
                  className="cursor-pointer h-6 w-6 rounded mr-2"
                  type="checkbox"
                  name="on_hold"
                  onChange={this.handleCheckBoxChange}
                  defaultChecked={this.state.on_hold}
                ></input>
                <label
                  htmlFor="status-is-onhold"
                  className="cursor-pointer font-medium text-sm leading-5 flex items-center text-gray-825"
                >
                  {this.props.t.on_hold}
                </label>
              </div>
            </div>
            <label className="block mb-1 font-medium text-xs leading-4 text-gray-725">
              {this.props.t.notes}
            </label>
            <textarea
              className="focus:outline-none bg-white box-border rounded-lg border px-4 py-10px font-medium text-sm leading-5 text-gray-875 border-gray-325 placeholder-gray-525 focus:border-blue-675 focus:border"
              placeholder={this.state.t.enter_note}
              rows="6"
              cols="90"
              name="notes"
              onChange={this.handleInputChange}
              defaultValue={this.state.notes}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
