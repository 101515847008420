import React from "react";

import { Select } from "../../ui-kit/components/Select";

class Condition extends React.Component {
  constructor(props) {
    super(props);

    const field = this.props.condition.field;
    let predicates;

    if (this.isNumberField(field)) {
      predicates = this.props.number_predicates;
    } else if (this.isCollectionField(field)) {
      predicates = this.props.collection_predicates;
    } else if (this.isSingularField(field)) {
      predicates = this.props.singular_predicates;
    }

    this.state = {
      predicates: predicates,
    };
  }

  changeField = (field, index) => {
    const conditions = this.props.conditions;
    conditions[index].field = field;
    conditions[index].value = "";

    if (this.isNumberField(field)) {
      conditions[index].predicate = this.props.number_predicates[0].value;
      this.setState({ predicates: this.props.number_predicates });
    } else if (this.isCollectionField(field)) {
      conditions[index].predicate = this.props.collection_predicates[0].value;
      this.setState({ predicates: this.props.collection_predicates });
    } else if (this.isSingularField(field)) {
      conditions[index].predicate = this.props.singular_predicates[0].value;
      this.setState({ predicates: this.props.singular_predicates });
    }

    this.props.changeCondition(conditions);
  };

  changePredicate = (predicate, index) => {
    const conditions = this.props.conditions;
    conditions[index].predicate = predicate;
    this.props.changeCondition(conditions);
  };

  changeMultiSelect = (values, index) => {
    const value = values.map(({ value }) => value).join(",");
    const conditions = this.props.conditions;
    conditions[index].value = value;
    this.props.changeCondition(conditions);
  };

  changeValue = (value, index) => {
    const conditions = this.props.conditions;
    conditions[index].value = value;
    this.props.changeCondition(conditions);
  };

  isNumberField = (field) => this.props.number_fields.includes(field);

  isCollectionField = (field) => this.props.collection_fields.includes(field);

  isSingularField = (field) => this.props.singular_fields.includes(field);

  render() {
    if (this.props.condition._destroy === 1) {
      return <div></div>;
    }

    return (
      <div className="flex w-full bg-white items-end mb-4">
        <div className="w-1/3 mr-5">
          <Select
            className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
            options={this.props.fields}
            onChange={(value) => this.changeField(value, this.props.index)}
            value={this.props.condition.field}
          />
        </div>

        <div className="w-1/3 mr-5 flex">
          <Select
            className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
            options={this.state.predicates}
            onChange={(value) => this.changePredicate(value, this.props.index)}
            value={this.props.condition.predicate}
          />
        </div>

        {this.props.condition.field === this.props.order_country_field ? (
          <div className="w-1/3 mr-5 flex">
            <Select
              className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
              onChange={(values) =>
                this.changeMultiSelect(values, this.props.index)
              }
              placeholder="Country"
              options={this.props.orderCountryOptions}
              value={this.props.condition.value}
              isClearable={false}
              isMulti
            />
          </div>
        ) : this.props.condition.field === this.props.return_type_field ? (
          <div className="w-1/3 mr-5 flex">
            <Select
              className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
              onChange={(values) =>
                this.changeMultiSelect(values, this.props.index)
              }
              placeholder="Return Type"
              options={this.props.return_types}
              value={this.props.condition.value}
              isClearable={false}
              isMulti
            />
          </div>
        ) : this.props.condition.field === this.props.return_reason_field ? (
          <div className="w-1/3 mr-5 flex">
            <Select
              className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
              onChange={(values) =>
                this.changeMultiSelect(values, this.props.index)
              }
              placeholder="Return Reason"
              options={this.props.reasons}
              value={this.props.condition.value}
              isClearable={false}
              isMulti
            />
          </div>
        ) : this.props.condition.field ===
          this.props.customer_risk_level_field ? (
          <div className="w-1/3 mr-5 flex">
            <Select
              className="bg-white box-border rounded-lg border focus:outline-none py-px px-px font-medium text-sm leading-5 text-gray-875 w-full focus:border-blue-675 focus:border capitalize"
              onChange={(value) => this.changeValue(value, this.props.index)}
              placeholder="Customer Risk Level"
              options={this.props.risk_levels}
              value={this.props.condition.value}
            />
          </div>
        ) : (
          <div className="w-1/3 mr-5">
            <input
              className="bg-white box-border rounded-lg border focus:outline-none font-medium text-sm leading-5 flex items-center text-gray-875 pl-4 pr-3 py-10px w-full focus:border-blue-675 focus:border"
              onChange={({ target: { value } }) =>
                this.changeValue(value, this.props.index)
              }
              value={this.props.condition.value}
            ></input>
          </div>
        )}

        <div className="bg-gray-225 rounded flex justify-center items-center p-10px">
          <svg
            width={18}
            height={20}
            viewBox="0 0 18 20"
            onClick={() =>
              this.props.deleteCondition(
                this.props.index,
                this.props.condition.id
              )
            }
            className="cursor-pointer"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.900391 4.6001H2.70039H17.1004"
              stroke="#52525B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.40117 4.6V2.8C5.40117 2.32261 5.59081 1.86477 5.92838 1.52721C6.26595 1.18964 6.72378 1 7.20117 1H10.8012C11.2786 1 11.7364 1.18964 12.074 1.52721C12.4115 1.86477 12.6012 2.32261 12.6012 2.8V4.6M15.3012 4.6V17.2C15.3012 17.6774 15.1115 18.1352 14.774 18.4728C14.4364 18.8104 13.9786 19 13.5012 19H4.50117C4.02378 19 3.56595 18.8104 3.22838 18.4728C2.89081 18.1352 2.70117 17.6774 2.70117 17.2V4.6H15.3012Z"
              stroke="#52525B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.20117 9.1001V14.5001"
              stroke="#52525B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.8008 9.1001V14.5001"
              stroke="#52525B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default Condition;
