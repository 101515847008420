import { getXCSRFToken } from "../../util/getXCSRFToken";
import {
  getCollectPlusReturnAddressSettingsRequestPayload,
  getTradeInItemPayload,
} from "../../util/api";

import { IntegrationResponse as ConnectToPaypalVariables } from "../integrations/components/Paypal/Paypal.types";

import { getConnectToPaypalRequestPayload } from "../integrations/components/Paypal/Paypal.util";

export function saveCollectPlusReturnAddressSettings(returnAddress) {
  const body = getCollectPlusReturnAddressSettingsRequestPayload(returnAddress);

  return fetch("/integrations/collect_plus/save", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function connectWithDhl({
  return_address,
  shipment_status_update,
  dhl_live_tracking_enabled,
  api_key,
}) {
  const body = {
    return_address: {
      dhl_username: return_address.dhl_username,
      dhl_password:
        return_address.dhl_password || return_address.dhl_password_filtered,
      dhl_receiver_id: return_address.dhl_receiver_id,
      note_attribute_name: return_address.note_attribute_name,
    },
    shipment_status_update,
    dhl_live_tracking_enabled,
    api_key,
  };
  return fetch("/integrations/dhl/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function generateApiKey() {
  return fetch("/integrations/public_api/generate", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
  });
}

function connectWithKlaviyo(klaviyo_integration) {
  const body = {
    merchant: {
      klaviyo_integration_attributes: {
        ...klaviyo_integration,
        private_api_key:
          klaviyo_integration.private_api_key ||
          klaviyo_integration.private_api_key_filtered,
      },
    },
  };

  return fetch("/integrations/klaviyo/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function connectWithShopify(body) {
  return fetch("/integrations/shopify/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function connectWithShopware(body) {
  return fetch("/integrations/shopware/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function setKlaviyoStatus(body) {
  return fetch("/integrations/klaviyo/change_status", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function setXentralStatus(body) {
  return fetch("/integrations/xentral/change_status", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function fetchMerchant(value) {
  return fetch(`/super_admin/search_merchant?query=${value}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
  });
}

function updateMerchantSettings(merchantParams) {
  const body = {
    merchant: merchantParams,
  };

  return fetch("/super_admin/update_merchant_settings", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function changeUserMerchant(id) {
  const body = {
    merchant_id: id,
  };
  return fetch("/super_admin/update_merchant", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}
function enableWhatIsNewBadge() {
  return fetch("/super_admin/enable_what_is_new_badge", {
    method: "PUT",
    headers: {
      "X-CSRF-Token": getXCSRFToken(),
    },
  });
}

function deleteTradeInItem(id) {
  return fetch(`/trade_in_items/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
  });
}

function createTradeInItem(params) {
  const requestPayload = getTradeInItemPayload(params);
  return fetch("/trade_in_items", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(requestPayload),
  });
}

function updateTradeInItem(id, params) {
  const requestPayload = getTradeInItemPayload(params);
  return fetch(`/trade_in_items/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(requestPayload),
  });
}

function connectWithXentral({
  id,
  username,
  baseUrl,
  shippingAndFeesProductId,
  password,
  createReturn,
  createConfirmedCreditNote,
  discountProductId,
}) {
  const body = {
    merchant: {
      xentral_integration_attributes: {
        id,
        username,
        base_url: baseUrl,
        shipping_and_fees_product_id: shippingAndFeesProductId,
        password,
        create_return: createReturn,
        create_confirmed_credit_note: createConfirmedCreditNote,
        discount_product_id: discountProductId,
      },
    },
  };

  return fetch("/integrations/xentral/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function updateStoreCreditSetting(body, id) {
  return fetch(`/store_credit_settings/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function createStoreCreditSetting(body) {
  return fetch("/store_credit_settings", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function getPaymentIntegration() {
  return fetch("/store_credit_settings/payment_integration");
}

function connectToPeoplevox({
  id,
  baseUrl,
  clientId,
  username,
  password,
  salesOrderNumberPrefix,
  salesOrderNumberSuffix,
}) {
  const body = {
    merchant: {
      peoplevox_integration_attributes: {
        id,
        base_url: baseUrl,
        client_id: clientId,
        username,
        password,
        sales_order_number_prefix: salesOrderNumberPrefix,
        sales_order_number_suffix: salesOrderNumberSuffix,
      },
    },
  };

  return fetch("/integrations/peoplevox/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

function changePeoplevoxStatus({ id, enabled }) {
  const body = {
    merchant: {
      peoplevox_integration_attributes: {
        id,
        enabled,
      },
    },
  };

  return fetch("/integrations/peoplevox/change_status", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export const connectToPaypal = (params: ConnectToPaypalVariables) => {
  const requestPayload = getConnectToPaypalRequestPayload(params);
  return fetch("/integrations/paypal/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(requestPayload),
  });
};

export function addReturnAddress(body) {
  return fetch("return_methods/return_address", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function addReturnMethod(body) {
  return fetch("return_methods", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function connectToFinecom({
  id,
  username,
  password,
  warehouse,
  active,
  productIdentifier,
}) {
  const body = {
    merchant: {
      finecom_integration_attributes: {
        id,
        username,
        password,
        warehouse,
        active,
        product_identifier: productIdentifier,
      },
    },
  };

  return fetch("/integrations/finecom/connect", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function setFinecomIntegrationStatus(body) {
  return fetch("/integrations/finecom/change_status", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function saveShopNowSettings(id, body) {
  return fetch(`/shop_now_settings/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body),
  });
}

export function fetchOrderInfoForAdmin(body) {
  return fetch('/mission_control/order', {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
    body: JSON.stringify(body)
  })
}

export function startFreeTrial() {
  return fetch("/shop_now_settings/start_trial", {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": getXCSRFToken(),
    },
  });
}

export {
  connectWithDhl,
  generateApiKey,
  connectWithKlaviyo,
  connectWithShopify,
  connectWithShopware,
  setKlaviyoStatus,
  setXentralStatus,
  fetchMerchant,
  changeUserMerchant,
  updateMerchantSettings,
  enableWhatIsNewBadge,
  deleteTradeInItem,
  createTradeInItem,
  updateTradeInItem,
  connectWithXentral,
  connectToPeoplevox,
  changePeoplevoxStatus,
};
