import React from "react";

export const CrossIcon = () => {
  return (
    <svg
      width={11}
      height={16}
      viewBox="3 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5L5 11.5"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4.5L12 11.5"
        stroke="#A1A1AA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
