import React from "react";
import { LineChart } from "bizcharts";
import moment from "moment";

import translationDE from "../../translations/home/translationDE.json";
import translationEN from "../../translations/home/translationEN.json";
import { ArrowUpIcon } from "~dashboard/ui-kit/icons/ArrowUp";
import { ArrowDownIcon } from "~dashboard/ui-kit/icons/ArrowDown";
import { NotificationIcon } from "../../ui-kit/icons/NotificationIcon";
import ReturnAddress from "../ReturnAddress";

class Home extends React.Component {
  constructor(props) {
    super(props);
    const chartData = Object.entries(
      this.props.dashboard_analytic.chart_data_in_last_14_days
    ).map(([registeredDate, returnsCount]) => ({
      year: moment(registeredDate).format("MMM DD"),
      Returns: returnsCount,
    }));

    this.state = {
      lastDays: 14,
      dashboardAnalytic: this.props.dashboard_analytic,
      t: this.props.lang === "en" ? translationEN : translationDE,
      chartData: chartData,
      mostReturnItemStatus: true,
      returnReasonStatus: false,
      showArrow: false,
      isNegativeReturnsCountDiffBetweenPeriods: false,
      editReturnAddress: true,
      prviousDashboardAnalyticUpdatedAt:
        this.props.dashboard_analytic.updated_at,
    };
    this.carbonOffset = (
      this.state.dashboardAnalytic.total_returns_count * 0.55
    ).toFixed(2);
  }

  lastDaysHandler = (e) => {
    this.updateChartAndArrowDataByDays(this.state.lastDays, e.target.value);
  };

  isRefreshCallInProgress = () => {
    return this.state.dashboardAnalytic.refresh_in_progress;
  };

  checkRefreshCallPeriodically = () => {
    const cron = setInterval(async () => {
      if (!this.isRefreshCallInProgress()) {
        clearInterval(cron);
      }

      await this.syncRefreshData();
    }, 3000);
  };

  syncRefreshData = async () => {
    const response = await fetch("/overview", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      const responseJson = await response.json();
      if (!responseJson.dashboard_analytic.refresh_in_progress) {
        const chartData = Object.entries(
          responseJson.dashboard_analytic[
            `chart_data_in_last_${this.state.lastDays}_days`
          ]
        ).map(([registeredDate, returnsCount]) => ({
          year: moment(registeredDate).format("MMM DD"),
          Returns: returnsCount,
        }));

        const returnsCountDiffBetweenPeriods =
          responseJson.dashboard_analytic[
            `returns_count_in_last_${this.state.lastDays}_days`
          ] -
          this.state.dashboardAnalytic[
            `returns_ count_in_last_${this.state.lastDays}_days`
          ];

        this.updateReturnData(returnsCountDiffBetweenPeriods);

        this.setState({
          dashboardAnalytic: responseJson.dashboard_analytic,
          chartData: chartData,
          showArrow: false,
        });
      }
    } else {
      const responseJson = await response.json();
      console.log(
        responseJson.error || response.status + " " + response.statusText
      );
    }
  };

  refreshData = () => {
    this.setState((prevState) => ({
      dashboardAnalytic: {
        ...prevState.dashboardAnalytic,
        refresh_in_progress: true,
      },
    }));
    fetch("/overview/refresh", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    });
    this.checkRefreshCallPeriodically();
  };

  changeMostReturnStatusHandler = () => {
    this.setState({ mostReturnItemStatus: true, returnReasonStatus: false });
  };

  changeReturnReasonHandler = () => {
    this.setState({ mostReturnItemStatus: false, returnReasonStatus: true });
  };

  customerReturnURL = (e) => {
    window.location = `/customer_returns?status=${e.target.value}`;
  };

  updateChartAndArrowDataByDays = (prevLastDaysState, updatedLastDaysState) => {
    const returnsCountDiffBetweenPeriods =
      this.state.dashboardAnalytic[
        `returns_count_in_last_${updatedLastDaysState}_days`
      ] -
      this.state.dashboardAnalytic[
        `returns_count_in_last_${prevLastDaysState}_days`
      ];

    this.updateReturnData(returnsCountDiffBetweenPeriods);

    const chartData = Object.entries(
      this.state.dashboardAnalytic[
        `chart_data_in_last_${this.state.lastDays}_days`
      ]
    ).map(([registeredDate, returnsCount]) => ({
      year: moment(registeredDate).format("MMM DD"),
      Returns: returnsCount,
    }));

    this.setState({
      chartData: chartData,
      showArrow: true,
      lastDays: updatedLastDaysState,
    });
  };

  updateReturnData = (returnsCountDiffBetweenPeriods) => {
    this.setState({
      isNegativeReturnsCountDiffBetweenPeriods:
        returnsCountDiffBetweenPeriods < 0,
      returnsCountDiffBetweenPeriods: Math.abs(returnsCountDiffBetweenPeriods),
    });
  };

  closeEditReturnAddressModal = () => {
    this.setState({
      editReturnAddress: false,
    });
  };

  render() {
    const mostReturnReasonsByDays =
      this.state.dashboardAnalytic[
        `most_return_reasons_in_last_${this.state.lastDays}_days`
      ];

    const reasonsTotalCount = Object.values(mostReturnReasonsByDays).reduce(
      (sum, reasonCount) => sum + reasonCount,
      0
    );

    return (
      <div>
        {this.props.current_merchant.is_return_address_required && (
          <ReturnAddress
            showEdit={this.state.editReturnAddress}
            closeEditReturnAddressModal={this.closeEditReturnAddressModal}
            returnAddress={this.state.return_address}
            lang={this.props.lang}
          />
        )}
        {this.props.current_merchant.shop_backend === "shopify_integration" &&
          this.props.notification && (
            <div
              className={`${
                this.props.notification?.bg_color || "bg-blue-500"
              } bg-opacity-10 rounded-lg px-2 py-6px ml-5 flex items-center mr-5 mb-5 text-left`}
            >
              <NotificationIcon
                color={`${this.props.notification?.icon_color || "#2F80ED"}`}
              />
              <div
                className={`font-medium text-xs13 leading-4 ${
                  this.props.notification?.text_color || "text-orange-500"
                } ml-1 font-inter}`}
                dangerouslySetInnerHTML={{
                  __html: this.props.notification.text,
                }}
              ></div>
            </div>
          )}
        {this.props.current_merchant.shop_backend === "shopify_integration" &&
          this.props.merchant_notification && (
            <div
              className={`${
                this.props.merchant_notification.bg_color || "bg-orange-500"
              } bg-opacity-10 rounded-lg px-2 py-6px ml-5 flex items-center mr-5 mb-5 text-left`}
            >
              <NotificationIcon
                color={`${
                  this.props.merchant_notification?.icon_color || "#DC6601"
                }`}
              />
              <div
                className={`font-medium text-xs13 leading-4 ${
                  this.props.merchant_notification?.text_color ||
                  "text-orange-500"
                } ml-1 font-inter}`}
                dangerouslySetInnerHTML={{
                  __html: this.props.merchant_notification.text,
                }}
              ></div>
            </div>
          )}
        <div className="flex justify-between items-center px-8 pb-3">
          <div className="flex items-center">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              className="min-w-24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75024 19.25H17.2502C18.3548 19.25 19.2502 18.3546 19.2502 17.25V9.75L12.0002 4.75L4.75024 9.75V17.25C4.75024 18.3546 5.64568 19.25 6.75024 19.25Z"
                stroke="#52525B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.74951 15.75C9.74951 14.6454 10.6449 13.75 11.7495 13.75H12.2495C13.3541 13.75 14.2495 14.6454 14.2495 15.75V19.25H9.74951V15.75Z"
                stroke="#52525B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="bg-gray-100 px-2 py-10px mx-5 rounded-lg flex items-center">
              <svg
                width={16}
                height={16}
                className="min-w-24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2976 0C14.8895 0.0326439 5.36076 0.270944 2.40323 3.23174C-0.626122 6.29374 -0.79587 11.0924 2.06373 13.9487C5.09308 16.7528 9.49348 16.7332 12.7481 13.5733C15.7089 10.6484 15.9798 1.08704 15.9798 0.678992C15.9243 0.261151 15.6925 0.042437 15.2976 0ZM11.8275 12.6593C9.10828 15.261 5.60233 15.0325 3.52291 13.4427L6.10831 10.8574L10.9429 10.3448C11.2824 10.3122 11.5566 10.0053 11.5207 9.62994C11.488 9.29045 11.1812 9.01624 10.8058 9.05215L7.58381 9.38185L8.96791 7.99775C9.23885 7.7268 9.23885 7.31876 8.96791 7.04455C8.69697 6.7736 8.28892 6.7736 8.01471 7.04455L6.58817 8.47108L6.9244 5.17405C6.99296 4.83456 6.71875 4.49506 6.34661 4.45915C6.00711 4.3906 5.66761 4.66481 5.63171 5.03695L5.11267 9.94659L2.56645 12.4928C0.699219 10.1098 1.00934 6.49613 3.35316 4.1523C5.32812 2.21325 11.6219 1.49835 14.6512 1.36125C14.5141 4.3906 13.7992 10.6843 11.8275 12.6593Z"
                  fill="#3F3F46"
                />
              </svg>
              <div className="font-inter text-xs13 leading-4 ml-1 font-medium text-gray-875">
                {this.state.t.carbon_offset_str1} {this.carbonOffset}{" "}
                {this.state.t.carbon_offset_str2}
              </div>
            </div>
          </div>
          <div className="font-inter">
            <a className="focus:outline-none font-inter" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.user.first_name + " "} {this.props.user.last_name}{" "}
              </p>
              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.user.email}
              </p>
            </a>
          </div>
        </div>
        <div className="border-b border-t border-gray-225 bg-white flex flex-col">
          <div className="flex">
            <div className="w-1/4 pl-8 pt-6 pb-4">
              <p className="font-medium text-sm leading-5 text-gray-625 font-inter">
                {this.state.t.total_return}
              </p>
              <p className="font-semibold text-2xl leading-6 text-gray-975 self-stretch mt-2 font-clash_grotesk">
                {this.state.dashboardAnalytic.total_returns_count}
              </p>
            </div>
            <div className="w-1/4 pt-6 pb-4">
              <p className="font-medium text-sm leading-5 text-gray-625 font-inter">
                {this.state.t.open}
              </p>
              <p className="font-semibold text-2xl leading-6 text-gray-975 self-stretch mt-2 font-clash_grotesk">
                {this.state.dashboardAnalytic.open_returns_count}
              </p>
            </div>
            <div className="w-1/4 pt-6 pb-4">
              <p className="font-medium inline-block text-sm leading-5 text-gray-625 font-inter">
                {this.state.t.needs_review}
              </p>
              <button
                className="inline-block ml-3 bg-white border box-border rounded-full border-gray-325 p-1 px-2 font-inter font-semibold text-xs leading-4 text-gray-875 focus:outline-none"
                value="needs_review"
                onClick={this.customerReturnURL}
              >
                {this.state.t.view}
              </button>
              <p className="font-semibold text-2xl leading-6 text-gray-975 self-stretch mt-2 font-clash_grotesk">
                {this.state.dashboardAnalytic.returns_needed_review_count}
              </p>
            </div>
            <div className="w-1/4 pl-8 pt-6 pb-4">
              <p className="font-medium text-sm leading-5 text-gray-625 font-inter inline-block">
                {this.state.t.on_hold}
              </p>
              <button
                className="inline-block ml-3 bg-white border box-border border-gray-325 rounded-full p-1 px-2 font-inter font-semibold text-xs leading-4 text-gray-875 focus:outline-none"
                value="on_hold"
                onClick={this.customerReturnURL}
              >
                {this.state.t.view}
              </button>
              <p className="font-semibold text-2xl leading-6 text-gray-975 self-stretch mt-2 font-clash_grotesk">
                {this.state.dashboardAnalytic.on_hold_returns_count}
              </p>
            </div>
          </div>
        </div>

        <div className="flow-root">
          <div className="float-left pt-4">
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              className="inline-block ml-9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16.6673V13.334"
                stroke="#A1A1AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 16.6673V8.33398"
                stroke="#A1A1AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 16.6673V3.33398"
                stroke="#A1A1AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="inline-block font-semibold font-inter text-base leading-6 text-gray-825 ml-2">
              {this.state.t.stats}
            </p>
          </div>
          <div className="flex float-right mr-8 pt-3">
            <select
              className="bg-white px-4 py-2 w-32 box-border border border-gray-325 rounded-lg focus:outline-none font-inter font-medium text-xs leading-4 text-gray-625"
              value={this.state.lastDays}
              onChange={this.lastDaysHandler}
            >
              <option value="14">{this.state.t.last_14}</option>
              <option value="30">{this.state.t.last_30}</option>
              <option value="90">{this.state.t.last_90}</option>
            </select>

            <button
              className={`w-24 border rounded-lg font-semibold text-xs leading-4 px-4 py-2 focus:outline-none bg-blue-675 text-white hover:bg-blue-625 focus:border-blue-625 mx-2 ${
                this.isRefreshCallInProgress() ? "cursor-default" : " "
              }`}
              onClick={this.refreshData}
              disabled={this.isRefreshCallInProgress()}
            >
              {this.isRefreshCallInProgress() ? (
                <div className=" flex justify-center items-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                </div>
              ) : (
                <span>{this.state.t.refresh}</span>
              )}
            </button>
          </div>
        </div>

        <div className="flex flex-wrap border-t border-gray-225 h-full mt-3">
          <div className="w-3/4 pl-8 pt-8 border-r border-gray-225">
            <LineChart
              data={this.state.chartData}
              xField="year"
              yField="Returns"
            />
          </div>
          <div className="w-1/4 flex flex-col">
            <div className="h-1/3 pl-8 py-3">
              <p className="font-medium font-inter text-sm leading-5 text-gray-725">
                {this.state.t.returns_count}
              </p>
              <h2 className="font-semibold inline-block font-clash_grotesk text-lg leading-6 text-gray-825">
                {
                  this.state.dashboardAnalytic[
                    `returns_count_in_last_${this.state.lastDays}_days`
                  ]
                }
              </h2>
              <div
                className={`inline-block ${
                  this.state.showArrow ? "" : "hidden"
                }`}
              >
                {!this.state.isNegativeReturnsCountDiffBetweenPeriods && (
                  <div className="bg-green-550 flex rounded ml-3 p-0.5">
                    <ArrowUpIcon />
                    <p className="inline-block font-inter text-xs10 font-bold leading-3 text-green-750">
                      {" "}
                      {this.state.returnsCountDiffBetweenPeriods}{" "}
                    </p>
                  </div>
                )}

                {this.state.isNegativeReturnsCountDiffBetweenPeriods && (
                  <div className="bg-red-650 flex rounded ml-3 p-0.5">
                    <ArrowDownIcon />
                    <p className="inline-block font-inter text-xs10 font-bold leading-3 text-red-750">
                      {" "}
                      {this.state.returnsCountDiffBetweenPeriods}{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="h-3/4 mt-2 pl-8 pr-8 pb-3">
              <p className="font-inter text-gray-725 font-semibold text-xs leading-4">
                {this.state.t.breakdown}
              </p>
              <div className="flex border-b border-gray-325 mt-2">
                <p className="flex-1 font-inter font-medium text-sm leading-4 text-gray-625 pb-1">
                  {this.state.t.refunds}
                </p>
                <h2 className="flex-1 font-clash_grotesk text-right text-gray-825 font-semibold text-sm leading-5 pb-1">
                  {this.state.dashboardAnalytic[
                    `refund_percentage_in_last_${this.state.lastDays}_days`
                  ] || 0}
                  %
                </h2>
              </div>
              {this.props.current_merchant.show_exchange && (
                <div className="flex border-b border-gray-325 mt-2">
                  <p className="flex-1 font-inter font-medium text-sm leading-4 text-gray-625">
                    {this.state.t.exchange}
                  </p>
                  <h2 className="flex-1 font-clash_grotesk text-right text-gray-825 font-semibold text-sm leading-5">
                    {this.state.dashboardAnalytic[
                      `exchange_percentage_in_last_${this.state.lastDays}_days`
                    ] || 0}
                    %
                  </h2>
                </div>
              )}
              {this.props.current_merchant.show_store_credit_option && (
                <div className="flex border-b border-gray-325 mt-2">
                  <p className="flex-1 font-inter font-medium text-sm leading-4 text-gray-625">
                    {this.state.t.store_credit}
                  </p>
                  <h2 className="flex-1 font-clash_grotesk text-right text-gray-825 font-semibold text-sm leading-5">
                    {this.state.dashboardAnalytic[
                      `store_credit_percentage_in_last_${this.state.lastDays}_days`
                    ] || 0}
                    %
                  </h2>
                </div>
              )}
              {this.props.current_merchant.shop_exchange_enabled && (
                <div className="flex border-b border-gray-325 mt-2">
                  <p className="flex-1 font-inter font-medium text-sm leading-4 text-gray-625">
                    {this.state.t.shop_now}
                  </p>
                  <h2 className="flex-1 font-clash_grotesk text-right text-gray-825 font-semibold text-sm leading-5">
                    {this.state.dashboardAnalytic[
                      `shop_now_percentage_in_last_${this.state.lastDays}_days`
                    ] || 0}
                    %
                  </h2>
                </div>
              )}
            </div>
            <div className="h-1/3 pl-8 py-4 border-b">
              <p className="font-inter font-medium text-sm leading-5 text-gray-725">
                {this.state.t.average_return_window}
              </p>
              <h2 className="font-clash_grotesk text-gray-825 font-semibold text-lg leading-6">
                {
                  this.state.dashboardAnalytic[
                    `avg_return_window_in_days_in_last_${this.state.lastDays}_days`
                  ]
                }
              </h2>
            </div>
          </div>
        </div>
        <div className="border-b mt-10">
          <button
            className={`${
              this.state.mostReturnItemStatus
                ? "border-b-2 inline-block border-gray-825 font-semibold text-gray-825"
                : "font-medium text-gray-625"
            } w-max pb-2 font-inter text-sm leading-5 ml-8 focus:outline-none`}
            onClick={this.changeMostReturnStatusHandler}
          >
            {this.state.t.most_return_items}
          </button>
          <button
            className={`${
              this.state.returnReasonStatus
                ? "border-b-2 inline-block border-gray-825 font-semibold text-gray-825"
                : "font-medium text-gray-625"
            } inline-block w-max pb-2 text-sm font-inter leading-5 ml-10 focus:outline-none`}
            onClick={this.changeReturnReasonHandler}
          >
            {this.state.t.return_reasons}
          </button>
        </div>
        {this.state.mostReturnItemStatus && (
          <div className="ml-4 pt-4 mt-8 mb-4 pl-4 pr-5">
            <table>
              <thead>
                <tr>
                  <th className="w-2/5 text-left mb-2 pb-2 font-inter font-medium text-xs leading-4 text-gray-525 uppercase ">
                    {this.state.t.items}
                  </th>
                  <th className="w-2/5 text-left font-inter pb-2 font-medium text-xs leading-4 text-gray-525 uppercase">
                    {this.state.t.top_reasons}
                  </th>
                  <th className="w-1/12 text-left font-inter pb-2 font-medium text-xs leading-4 text-gray-525 uppercase">
                    {this.state.t.returns_count}
                  </th>
                  <th className="w-1/12 text-left font-inter pb-2 font-medium text-xs leading-4 text-gray-525 uppercase">
                    {this.state.t.refunds}
                  </th>
                  {this.props.current_merchant.show_exchange && (
                    <th className="w-1/12 text-left font-inter pb-2 font-medium text-xs leading-4 text-gray-525 uppercase">
                      {this.state.t.exchanges}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.dashboardAnalytic[
                  `most_returned_items_in_last_${this.state.lastDays}_days`
                ].map((item, index) => (
                  <tr className="border-t" key={index}>
                    <td className="w-4/5 text-left font-inter flex flex-row font-medium text-xs leading-4 text-gray-525 py-3 items-center">
                      <div className="w-10 h-10 flex items-center justify-center border rounded">
                        {item.image && (
                          <img
                            className="max-h-38px"
                            src={item.image}
                            alt="img"
                          />
                        )}
                      </div>
                      <div className="inline-block ml-3 font-inter font-medium text-xs leading-4 flex flex-col text-gray-825">
                        {item.name}
                        <div className="font-medium text-xs leading-4 text-gray-525">
                          {this.state.t.last_returned}:{" "}
                          {moment(item.last_returned_date).isValid()
                            ? moment(item.last_returned_date).format(
                                "DD MMM, YYYY"
                              )
                            : item.last_returned_date}
                        </div>
                      </div>
                    </td>
                    <td className="w-2/5 text-left font-inter font-medium text-xs leading-4">
                      {Object.entries(item.reasons).map(
                        ([reasonName, reasonCount], index) => (
                          <div
                            className="bg-gray-225 w-max py-1 pl-3 mr-2 rounded-lg inline-block text-gray-725 mb-1"
                            key={index}
                          >
                            {reasonName}
                            <p className="font-bold text-gray-875 inline-block bg-gray-325 ml-2 p-1 mr-2 rounded">
                              {reasonCount}
                            </p>
                          </div>
                        )
                      )}
                    </td>
                    <td
                      className={`${
                        this.props.current_merchant.show_exchange
                          ? "w-1/12"
                          : "w-2/5"
                      } text-left font-inter font-medium text-xs leading-4 text-gray-725`}
                    >
                      {item.count}
                    </td>
                    <td
                      className={`${
                        this.props.current_merchant.show_exchange
                          ? "w-1/12"
                          : "w-2/5"
                      } text-left font-inter font-medium text-xs leading-4 text-gray-725`}
                    >
                      {item.refund}
                    </td>
                    {this.props.current_merchant.show_exchange && (
                      <td className="w-1/12 text-right font-inter font-medium text-xs leading-4 text-gray-725">
                        {item.exchange}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.state.returnReasonStatus && (
          <div className="ml-4 pt-4 mt-8 mb-4 pl-4 pr-5">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="w-1/3 text-left font-inter pb-2 font-medium text-xs leading-4 text-gray-525 uppercase">
                    {this.state.t.reasons}
                  </th>
                  <th className="text-left font-inter pb-2 font-medium text-xs leading-4 text-gray-525">
                    {this.state.t.in_percentage}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(mostReturnReasonsByDays).map(
                  ([reasonName, reasonCount], index) => (
                    <tr className="border-t" key={index}>
                      <td className="w-1/3 text-left font-inter font-medium text-xs leading-4 text-gray-725 py-6">
                        {reasonName}
                      </td>
                      <td className="text-left font-inter font-normal text-xs leading-4 text-gray-725 py-6">
                        {((reasonCount / reasonsTotalCount) * 100).toFixed(1)}%
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default Home;
