import React from "react";
import { isEmpty, sumBy } from "lodash";
import moment from "moment";

import { Typography } from "../../../../../../../ui-kit/components/Typography/Typography";
import { CrossIcon } from "../../../../../../../ui-kit/icons/CrossIcon";
import { CommentIcon } from "../../../../../../../ui-kit/icons/CommentIcon";
import { ItemFieldDropdown } from "../ItemFieldDropdown";

export const Item = ({
  t,
  item,
  showImageModal,
  isScayleIntegration,
  isMainItem,
  returnTypeOptions,
  onChange,
  isItemConditionDisplayable,
  conditionOptions,
  currency,
  canCancel,
  hideMarkItemAsMissing,
  onClickMarkAsMissingHandler,
  isCancelled,
  isDisabled,
  isCompleted,
}) => {
  const originalPrice =
    (isMainItem && !isEmpty(item.sub_items))
      ? sumBy(item.sub_items, subItem => parseFloat(subItem.original_amount))
      : parseFloat(item.original_amount);

  const price =
    (isMainItem && !isEmpty(item.sub_items))
      ? sumBy(item.sub_items, subItem => parseFloat(subItem.amount))
      : parseFloat(item.amount);

  const returnTypesAsDropdown = () => {
    return (
      <ItemFieldDropdown
        name="item_return_type"
        option={item.return_type}
        options={returnTypeOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        className="bg-gray-225"
      />
    );
  };

  const exchangeReturnTypeAsBadge = () => {
    return (
      <div className="w-max flex flex-wrap justify-between items-center bg-gray-225 rounded-lg px-2 py-1">
        <Typography
          element="div"
          weight="medium"
          size="xs"
          height="4"
          color="gray-725"
        >
          {t.exchanged_for}
        </Typography>

        <Typography
          weight="semibold"
          size="xs"
          height="4"
          color="gray-825"
          className="ml-1"
        >
          {item.changed_variant_title}
        </Typography>
      </div>
    );
  };

  const returnTypeAsBadge = () => {
    return (
      <div className="w-max flex flex-wrap justify-between items-center bg-gray-225 rounded-lg px-2 py-1">
        <Typography
          element="div"
          weight="medium"
          size="xs"
          height="4"
          color="gray-725"
        >
          {t[item.return_type]}
        </Typography>
      </div>
    );
  };

  const renderReturnType = () => {
    const isRenderNothing = isScayleIntegration && !isMainItem;
    const isRenderReturnTypesAsDropdown =
      isScayleIntegration && isMainItem && returnTypeOptions.length > 1;
    const isRenderExchangeReturnTypeAsBadge =
      !isScayleIntegration && item.return_type === "exchange";

    if (isRenderNothing) {
      return null;
    }

    if (isRenderReturnTypesAsDropdown) {
      return returnTypesAsDropdown();
    }

    if (isRenderExchangeReturnTypeAsBadge) {
      return exchangeReturnTypeAsBadge();
    }

    return returnTypeAsBadge();
  };

  return (
    <>
      <div
        className={`flex items-center mt-5 justify-between ${
          item.is_cancelled ? "opacity-50" : ""
        }`}
      >
        <div className="w-full image-container">
          <div className="flex items-center justify-center w-72px h-72px border rounded">
            {item.item_picture_image_url && (
              <img
                className="max-h-70px cursor-pointer transform hover:scale-150"
                src={item.item_picture_image_url}
                onClick={() => showImageModal(item.item_picture_image_url)}
                alt="img"
              />
            )}
          </div>

          <div className="ml-6">
            <div className="flex w-full flex-wrap justify-between items-baseline gap-4 mb-1">
              <Typography
                element="p"
                weight="semibold"
                size="xs"
                height="4"
                color="gray-825"
                className="flex-1"
              >
                {item.name}
              </Typography>

              <div className="flex flex-1 justify-right items-center gap-x-3">
                {originalPrice > price && (
                  <Typography
                    element="p"
                    weight="medium"
                    size="xs"
                    height="4"
                    color="gray-825"
                    className="line-through"
                  >
                    {new Intl.NumberFormat("en", {
                      style: "currency",
                      currency: currency
                    }).format(originalPrice)}
                  </Typography>
                )}

                <Typography
                  element="p"
                  weight="medium"
                  size="xs"
                  height="4"
                  color="gray-825"
                >
                  {new Intl.NumberFormat("en", {
                    style: "currency",
                    currency: currency
                  }).format(price)}
                </Typography>

                <CrossIcon />

                <Typography
                  element="p"
                  weight="medium"
                  size="xs"
                  height="4"
                  color="gray-825"
                >
                  {item.quantity}
                </Typography>

                <Typography
                  element="p"
                  weight="semibold"
                  size="lg"
                  height="6"
                  color="gray-825"
                  className="flex-grow text-right font-clash_grotesk"
                >
                  {new Intl.NumberFormat("en", {
                    style: "currency",
                    currency: currency
                  }).format(
                    isScayleIntegration && item.return_type === "exchange"
                      ? 0
                      : price * item.quantity
                  )}
                </Typography>
              </div>
            </div>

            <div className="flex flex-wrap gap-x-6">
              {item.rma && (
                <div className="flex gap-x-3 w-full">
                  <Typography
                    element="p"
                    weight="medium"
                    size="xs"
                    height="4"
                    color="gray-725"
                  >
                    {t.rma}
                  </Typography>
                  <Typography
                    element="p"
                    weight="normal"
                    size="xs"
                    height="4"
                    color="gray-625"
                  >
                    {item.rma}
                  </Typography>
                </div>
              )}

              {item.sku && (
                <div className="flex gap-x-3">
                  <Typography
                    element="p"
                    weight="medium"
                    size="xs"
                    height="4"
                    color="gray-725"
                  >
                    SKU
                  </Typography>
                  <Typography
                    element="p"
                    weight="normal"
                    size="xs"
                    height="4"
                    color="gray-625"
                  >
                    {item.sku}
                  </Typography>
                </div>)}

              {item.ean && (
                <div className="flex gap-x-3">
                  <Typography
                    element="p"
                    weight="medium"
                    size="xs"
                    height="4"
                    color="gray-725"
                  >
                    EAN
                  </Typography>
                  <Typography
                    element="p"
                    weight="normal"
                    size="xs"
                    height="4"
                    color="gray-625"
                  >
                    {item.ean}
                  </Typography>
                </div>
              )}
            </div>

            {/* TODO: item.additional_comments would be removed later */}

            {isMainItem ? (
              item.additional_comments_in_html ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.additional_comments_in_html,
                  }}
                ></div>
              ) : item.additional_comments ? (
                <div className="flex flex-wrap items-center mt-4">
                  {item.additional_comments.split("\n").map((line, index) => (
                    <Typography key={index} element="div" weight="normal" size="xs" height="4" color="gray-625" className="w-full">{line.trim()}</Typography>
                  ))}
                </div>
              ) : null
            ) : null}

            <div className="flex flex-wrap gap-x-4 gap-y-2 mt-4">
              {item.internal_reason && (
                <Typography
                  element="div"
                  weight="medium"
                  size="xs"
                  height="4"
                  color="gray-725"
                  className="w-max bg-white border box-border rounded-80 border-gray-225 px-2 py-1"
                >
                  {item.internal_reason}
                </Typography>
              )}

              {renderReturnType()}

              {isItemConditionDisplayable && (
                <ItemFieldDropdown
                  name="item_condition_id"
                  option={item.item_condition_id}
                  options={conditionOptions}
                  onChange={onChange}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {isMainItem && item.return_reason_text && (
        <div className="ml-24 mt-6 bg-gray-100 rounded-11">
          <div className="px-4 py-4">
            <div className="flex items-center">
              <CommentIcon />

              <Typography
                element="p"
                weight="semibold"
                size="xs13"
                height="4"
                color="gray-825"
                className="ml-2"
              >
                {t.comment_by_user}
              </Typography>
            </div>

            <div className="bg-gray-225 mt-2 rounded-tr-12 rounded-br-12 rounded-bl-12 inline-flex">
              <Typography
                element="p"
                weight="normal"
                size="xs"
                height="4"
                color="gray-875"
                className="py-2 px-2"
              >
                {item.return_reason_text}
              </Typography>
            </div>
          </div>
        </div>
      )}

      {isMainItem && item.return_reason_pictures.length > 0 && (
        <div className="ml-24 mt-6">
          <Typography
            element="p"
            weight="medium"
            size="xs13"
            height="4"
            color="gray-825"
          >
            {t.image_uploaded}
          </Typography>

          <div className="flex items-center flex-wrap">
            {item.return_reason_pictures.map((i, index) => (
              <div
                className="flex items-center justify-center w-48px h-48px border rounded-lg mt-2 cursor-pointer mr-3"
                onClick={() => showImageModal(i.image_url)}
                key={index}
              >
                <img
                  className="max-h-46px transform hover:scale-150"
                  src={i.image_url}
                  alt="img"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {isMainItem && (
        <div className="ml-24 mt-6 flex items-center">
          {canCancel && !hideMarkItemAsMissing && (
            <button
              className="border box-border rounded-lg font-semibold text-xs leading-4 text-white focus:outline-none py-2 px-4 bg-red-525 focus:bg-red-525 hover:bg-red-800"
              name="mark_as_missing"
              onClick={onClickMarkAsMissingHandler}
            >
              {t.mark_as_missing}
            </button>
          )}

          {item.is_cancelled && !isCancelled && !isCompleted && (
            <div className="flex items-center">
              <Typography
                element="p"
                weight="normal"
                size="xs"
                height="4"
                color="gray-825"
              >
                {t.mark_missing_on}{" "}
                {moment(item.cancelled_date).format("MMM D YYYY")}
              </Typography>

              <button
                className="not-italic font-semibold text-xs leading-4 text-blue-675 ml-6 focus:outline-none"
                name="undo_mark_as_missing"
                onClick={onChange}
              >
                {t.undo}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
