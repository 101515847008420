import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

import { Toggle } from "../../../../ui-kit/components/Toggle";
import Checkbox from "../../../../ui-kit/components/Checkbox";

export const ExchangeSettings = ({
  returnPolicy,
  t,
  handleInputChange,
  handleCheckBoxChange,
  handleTagifyInputs,
  handleTagifyInputsForExchangeReturnPolicy,
}) => {
  const {
    variant_exchanges_enabled,
    exchange_same_item,
    exchange_same_priced_item,
    exchange_without_track_quantity,
    same_variant_exchange_excluded,
    exchange_period,
    inventory_threshold,
    eligible_countries,
    exchange_shipping_method,
    exchange_ineligible_product_categories,
    exchange_ineligible_order_tags,
    exchange_setting,
    draft_order_inventory_hold_period_days,
  } = returnPolicy;

  const { image_styled_product_options, badge_styled_product_options } =
    exchange_setting;

  return (
    <div className="mt-8 px-8">
      <p className="font-semibold text-base leading-6 text-gray-825">
        Exchanges
      </p>

      <div
        className={`flex items-center mt-4 ${
          !variant_exchanges_enabled ? "border-b pb-8" : ""
        }`}
      >
        <Toggle
          id="variant_exchanges_enabled"
          name="variant_exchanges_enabled"
          className="w-1/3"
          onChange={(value) =>
            handleCheckBoxChange({
              name: "variant_exchanges_enabled",
              value,
            })
          }
          checked={variant_exchanges_enabled}
          label={{
            text: t.enable_variant_exchanges,
            className:
              "inline font-medium text-sm leading-5 text-gray-825 cursor-pointer",
          }}
        />
      </div>

      {variant_exchanges_enabled && (
        <div className="border-b pb-8">
          <div className="w-1/2 mt-4">
            <Checkbox
              name="exchange_same_priced_item"
              value={exchange_same_priced_item}
              label={{
                text: t.same_priced_item_exchanges,
                className: "ml-3 font-medium text-sm leading-5 text-gray-825",
              }}
              handleChange={handleCheckBoxChange}
            />
          </div>

          <div className="w-1/2 mt-4">
            <Checkbox
              name="exchange_same_item"
              value={exchange_same_item}
              label={{
                text: t.only_same_item_exchanges,
                className: "ml-3 font-medium text-sm leading-5 text-gray-825",
              }}
              handleChange={handleCheckBoxChange}
            />
          </div>

          <div className="w-1/2 mt-4">
            <Checkbox
              name="same_variant_exchange_excluded"
              value={same_variant_exchange_excluded}
              label={{
                text: t.exclude_same_variant_exchange,
                className: "ml-3 font-medium text-sm leading-5 text-gray-825",
              }}
              handleChange={handleCheckBoxChange}
            />
          </div>

          <div className="mt-4 flex items-centers">
            <div className="w-1/3">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.minimum_inventory}
              </p>

              <input
                className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                type="number"
                min="0"
                name="inventory_threshold"
                onChange={handleInputChange}
                value={inventory_threshold}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.exchange_option}
              </p>
            </div>
            <div className="w-1/3 ml-5">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.exchange_period_days}
              </p>

              <input
                className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                type="number"
                min="0"
                name="exchange_period"
                onChange={handleInputChange}
                value={exchange_period}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.exchange_days}
              </p>
            </div>
          </div>
          <div className="mt-4 flex items-centers">
            <div className="w-1/3">
              <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
                {t.draft_order_inventory_hold_period_days}
              </p>

              <input
                className="w-full bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
                type="number"
                min="1"
                name="draft_order_inventory_hold_period_days"
                onChange={handleInputChange}
                value={draft_order_inventory_hold_period_days}
              />

              <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
                {t.draft_order_inventory_hold_period_days_description}
              </p>
            </div>
          </div>
          <div className="w-1/2 mt-4">
            <Checkbox
              name="exchange_without_track_quantity"
              value={exchange_without_track_quantity}
              label={{
                text: t.skip_stock,
                className: "ml-3 font-medium text-sm leading-5 text-gray-825",
              }}
              handleChange={handleCheckBoxChange}
            />
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.countries_exchange_option}
            </p>

            <Tags
              className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="eligible_countries"
              onChange={handleTagifyInputs}
              value={eligible_countries}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.eligible_countries}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.exchange_ineligible_order}
            </p>

            <Tags
              className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="exchange_ineligible_order_tags"
              onChange={handleTagifyInputs}
              value={exchange_ineligible_order_tags}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.exchange_ineligible_order_description}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.exchange_ineligible_product}
            </p>

            <Tags
              className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="exchange_ineligible_product_categories"
              onChange={handleTagifyInputs}
              value={exchange_ineligible_product_categories}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.blocked_product_tags}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.image_styled_product_options}
            </p>

            <Tags
              className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="image_styled_product_options"
              onChange={handleTagifyInputsForExchangeReturnPolicy}
              value={image_styled_product_options}
            />
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.badge_styled_product_options}
            </p>

            <Tags
              className="w-68% bg-white box-border rounded-lg border pl-1 font-medium text-sm leading-5 text-gray-875 focus:outline-none focus:border focus:border-blue-675 border-gray-325"
              name="badge_styled_product_options"
              onChange={handleTagifyInputsForExchangeReturnPolicy}
              value={badge_styled_product_options}
            />

            <p className="font-normal text-xs13 leading-4 text-gray-725 mt-2">
              {t.default_product_option_render_style_note}
            </p>
          </div>

          <div className="mt-4">
            <p className="font-medium text-xs leading-4 text-gray-725 mb-1">
              {t.shipping_method_name}
            </p>

            <input
              className="w-68% bg-white border box-border border-gray-325 rounded-lg font-medium text-sm leading-5 text-gray-875 py-10px px-4 focus:outline-none focus:border-blue-675 focus:border"
              type="text"
              name="exchange_shipping_method"
              onChange={handleInputChange}
              value={exchange_shipping_method}
            />
          </div>
        </div>
      )}
    </div>
  );
};
